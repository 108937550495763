<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
        <div class="field">
            <label>Username</label>
            <InputText v-model="item.dm_code" required="true" autofocus :class="{ 'p-invalid': errorEdit.username }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.username" >{{ errorEdit.username[0] }}</small >
        </div>
        <div class="field">
            <label>Name</label>
            <InputText v-model="item.dm_name" required="true" autofocus :class="{ 'p-invalid': errorEdit.name }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.name" >{{ errorEdit.name[0] }}</small >
        </div>
        <div class="field">
            <label>Phone</label>
            <InputText v-model="item.dm_phone" required="true" autofocus :class="{ 'p-invalid': errorEdit.phone }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.phone" >{{ errorEdit.phone[0] }}</small >
        </div>
        <div class="field">
            <label>Email</label>
            <InputText v-model="item.dm_email" required="true" autofocus :class="{ 'p-invalid': errorEdit.email }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.email" >{{ errorEdit.email[0] }}</small >
        </div>
        <div class="field">
            <label>Distributor</label>
            <Dropdown  dataKey="db_id" 
                    ref="ddistributor" v-model="item.db_id" 
                    :loading="loadingDropdownDistributor" 
                    :options="dataDropdownDistributor" 
                    :class="{ 'p-invalid': errorEdit.db_id }" 
                    optionLabel="db_name" optionValue="db_id" 
                    placeholder="Choose Distributor" :filter="true" 
                    :showClear="true" @filter="searchDropdownDistributor($event,'edit')"
            />
            <small class="p-invalid" style="color: red" v-if="errorEdit.db_id" >{{ errorEdit.db_id[0] }}</small>
        </div>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Update" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownDistributor: false,

            // dataDropdown
            dataDropdownDistributor: null,

            // edit
            editItemDialog: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        editItemDialog: {
            handler() {
                setTimeout(() => {
                    if(this.editItemDialog == true){
                        this.searchDropdownDistributor('', 'edit', this.item.db_name);
                    }
                }, 500);
            },
        }
    },
    computed:{
        ...mapGetters(['errorEdit']),
    },
    methods: {
        // DROPDOWN
        searchDropdownDistributor(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.ddistributor.filterValue = valueEdit;
                }

                if (purpose == "edit") {
                    this.loadingDropdownDistributor = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-distributor',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if (purpose == "edit") {
                        this.dataDropdownDistributor = res.data.data;
                        this.loadingDropdownDistributor = false;
                    } else if (purpose == null) {
                        this.dataDropdownDistributor = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // EDIT
		hideDialog() {
			this.editItemDialog = false;
		},
		updateItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/delivery-man/update',
                data: {
                    "dm_id" : this.item.dm_id,
                    "username" : this.item.dm_code,
                    "name" : this.item.dm_name,
                    "phone" : this.item.dm_phone,
                    "email" : this.item.dm_email,
                    "db_id" : this.item.db_id,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Update', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.editItemDialog = false;
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Update', life: 3000,});
            });
		},
    }
}
</script>