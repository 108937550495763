<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="username">Username</label>
                <InputText id="username" v-model="forms.username" required="true" autofocus :class="{ 'p-invalid': errorAdd.username }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.username" >{{ errorAdd.username[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="name">Name</label>
                <InputText id="name" v-model="forms.name" required="true" autofocus :class="{ 'p-invalid': errorAdd.name }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.name" >{{ errorAdd.name[0] }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="phone">Phone</label>
                <InputText id="phone" v-model="forms.phone" required="true" autofocus :class="{ 'p-invalid': errorAdd.phone }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.phone" >{{ errorAdd.phone[0] }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="email">Email</label>
                <InputText id="email" v-model="forms.email" required="true" autofocus :class="{ 'p-invalid': errorAdd.email }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.email" >{{ errorAdd.email[0] }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Distributor</label>
                    <Dropdown id="db_id" dataKey="db_id" v-model="forms.db_id" :loading="loadingDropdownDistributor"
                        :options="dataDropdownDistributor" :class="{ 'p-invalid': errorAdd.db_id }"
                        optionLabel="db_name" optionValue="db_id" placeholder="Choose Distributor"
                        :filter="true" :showClear="true" @filter="searchDropdownDistributor($event, 'add')"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.db_id">{{
                        errorAdd.db_id[0]
                }}</small>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Save" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownDistributor: false,

            // dataDropdown
            dataDropdownDistributor: null,

            // addNew
            forms: {
                username: null,
                name: null,
                phone: null,
                email: null,
                db_id: null,
            },
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownDistributor('');
    },
    computed:{
        ...mapGetters(['errorAdd']),
    },
    methods: {
        // DROPDOWN
        searchDropdownDistributor(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.ddistributor.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownDistributor = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-distributor',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "add") {
                            this.dataDropdownDistributor = res.data.data;
                            this.loadingDropdownDistributor = false;
                        } else if (purpose == null) {
                            this.dataDropdownDistributor = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/delivery-man/create',
                data: {
                    "username": this.forms.username,
                    "name": this.forms.name,
                    "phone": this.forms.phone,
                    "email": this.forms.email,
                    "db_id": this.forms.db_id,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Save', life: 3000,});
            });
        },
        clearForms() {
            this.forms.username = null;
            this.forms.name = null;
            this.forms.phone = null;
            this.forms.email = null;
            this.forms.db_id = null;
        },
    }
}
</script>